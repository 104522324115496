import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

const ProtectedRoutes = () => {
  let { auth } = useAuth();
  // const queryString = new URLSearchParams(window.location.search);
  // const access_token = queryString.get("access_token");
  // if (access_token) {
  // }
  return auth ? <Outlet /> : <Navigate to="/" />;
};
export default ProtectedRoutes;
