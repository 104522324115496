import React, { useEffect, useRef, useState } from "react";
import { supabase } from "../utils/supaBaseClient";
import {
  HeartIcon,
  MapPinIcon,
  ShareIcon,
  CalendarIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/solid";
import Comments from "../components/Comments";
import { Link } from "react-router-dom";
import LoadingComponents from "../components/LoadingComponents";
import { Maps } from "../components/Maps";
import Modal from "../features/Modal";
import { google, office365, ics, office365Mobile } from "calendar-link";
import { initOneSignal } from "../OneSignalConfig";
import OneSignalReact from "react-onesignal";

function Events({ user }) {
  const [loading, setLoading] = useState(true);
  const [overyonder, setOveryonder] = useState([]);
  const [upvote, setUpvote] = useState(0);
  const [categories, setCategories] = useState({});
  const [selectedTab, setSelectedTab] = useState("All Posts");
  const calendarContainer = useRef({});
  const calendarElement = useRef({});
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [showAttendModal, setShowAttendModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [unsubscribedEvent, setUnsubscribedEvent] = useState(null);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const readableDate = (e) => {
    const date = new Date(e);
    return `${date.toDateString()} ${date.toLocaleTimeString()}`;
  };

  const deleteEvent = async (event_id) => {
    try {
      await supabase
        .from("user_event")
        .delete()
        .match({ user_id: user.id, event_id: event_id });
    } catch (error) {
      console.log(error.message);
    }
    console.log("Event deleted successfully!");
  };

  const attend = async (id, event_id) => {
    const { data, error } = await supabase
      .from("user_event")
      .insert({ user_id: user.id, event_id: event_id });
    if (error) {
      console.log(error.message);
    }
    console.log(data);
  };

  const toggleStyleEvent = async (id) => {
    calendarContainer.current[id].classList.toggle("bg-over-yonder-default");
    calendarElement.current[id].classList.toggle("text-white");
    calendarElement.current[id].classList.toggle("text-over-yonder-default");
  };


  const attendEvent = async (id, event_post) => {
    toggleStyleEvent(id);

    if (
      calendarContainer.current[id].classList.contains("bg-over-yonder-default")
    ) {
      // checkAndGenerateToken();
      attend(user.id, event_post.id);
      setSelectedEvent(event_post);
      setShowAttendModal(true);
    } else {
      await deleteEvent(event_post.id);
      setUnsubscribedEvent(event_post);
      setShowUnsubscribeModal(true);
    }
  };



  const addToGoogleCalendar = (event) => {
    const ev = {
      title: event.title,
      description: event.description,
      start: event.start_date,
      end: event.end_date,
      reminders: [
         { method: "popup", minutes: 30 },
         { method: "popup", minutes: 15 },
         { method: "popup", minutes: 5 },
       ],
       location: `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`,
       url: `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`,
     };
   
    window.open(google(ev), "_blank");
    
  };

  const addToOutlook = (event) => {
    const ev = {
      title: event.title,
      description: event.description,
      start: event.start_date,
      end: event.end_date,
      reminders: [
        { method: "popup", minutes: 30 },
        { method: "popup", minutes: 15 },
        { method: "popup", minutes: 5 },
      ],
      location: `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`,
      url: `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`,
    };
    console.log(office365(ev));

    const device_info = ["Mobile", "Android", "iPhone", "iPad"];
    const isMobile = device_info.some((device) =>
      navigator.userAgent.includes(device)
    );
    if (isMobile) {
      window.open(office365Mobile(ev), "_blank");
    }else{
      window.open(office365(ev), "_blank");
    }
  };

  async function getCategories() {
    let { data, error } = await supabase
      .from("interest_categories")
      .select("*");
    if (error) {
      console.log(error.message);
    } else if (data) {
      setCategories(data);
      console.log(data);
    }
    console.log(categories);
  }

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    async function getEvents() {
      setLoading(true);

      let { data: event_post, error } = await supabase
        .from("event_post")
        .select(
          "* , user_profiles(id, full_name, first_name, last_name, avatar_url), schools(college_name), interest_categories(title), event_interest_tags(tag_id, event_id), user_event(user_id, event_id), event_comments(created_by, event_id,text)"
        )
        .order("created_at", { ascending: false });
      if (error) {
        console.log(error.message);
      } else if (event_post) {
        if (selectedTab === "All Posts") {
          setOveryonder(event_post);
        } else {
          const filteredEvents = event_post.filter(
            (event) => event.interest_categories.title === selectedTab
          );
          setOveryonder(filteredEvents);
        }
        setLoading(false);
      }
    }
    getEvents();
  }, [user, selectedTab]);
  const downloadICSFile = (event) => {
    const startTime =
      new Date(event.start_date)
        .toISOString()
        .replace(/[-:]/g, "")
        .slice(0, -5) + "Z";
    const endTime =
      new Date(event.end_date).toISOString().replace(/[-:]/g, "").slice(0, -5) +
      "Z";
    const now =
      new Date().toISOString().replace(/[-:]/g, "").slice(0, -5) + "Z";
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//OverYonder//iCal Event Maker
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:America/Boise
LAST-MODIFIED:20240422T053451Z
TZURL:https://www.tzurl.org/zoneinfo-outlook/America/Boise
X-LIC-LOCATION:America/Boise
BEGIN:DAYLIGHT
TZNAME:MDT
TZOFFSETFROM:-0700
TZOFFSETTO:-0600
DTSTART:19700308T020000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU
END:DAYLIGHT
BEGIN:STANDARD
TZNAME:MST
TZOFFSETFROM:-0600
TZOFFSETTO:-0700
DTSTART:19701101T020000
RRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${now}
UID:${event.id}@overyonder.com
DTSTART;TZID=America/Boise:${startTime}
DTEND;TZID=America/Boise:${endTime}
SUMMARY:${event.title}
DESCRIPTION:${event.description}\\nhttps://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}
LOCATION:
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:${event.title}
TRIGGER:-PT30M
END:VALARM
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:${event.title}
TRIGGER:-PT15M
END:VALARM
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:${event.title}
TRIGGER:-PT5M
END:VALARM
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:${event.title}
TRIGGER:PT0M
END:VALARM
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `${event.title}.ics`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };





  useEffect(() => {
    console.log("Initializing OneSignal");
    initOneSignal();
  }, []);

  useEffect(() => {
    OneSignalReact.on("notificationDisplay", (event) => {
      console.log("OneSignal notification displayed:", event);
      setNotification({
        title: event.heading,
        body: event.content,
      });
    });
  }, []);

  // Filter events based on search query
  const filteredEvents = overyonder.filter(
    (event) =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      event.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const NoEventsMessage = ({ message }) => (
    <div className="flex flex-col items-center justify-center h-full text-center mt-7 p-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        className="w-16 h-16 text-over-yonder-default animate-bounce"
        fill="currentColor"
      >
        <circle cx="50" cy="50" r="40" />
        <text x="50" y="65" textAnchor="middle" fontSize="40" fill="white">
          OY
        </text>
      </svg>
      <h2 className="text-2xl font-bold text-gray-700">{message}</h2>
      <p className="text-gray-500 mt-2">
        Try adjusting your search or filters.
      </p>
    </div>
  );

  return (
    <div className="row-span-4 h-full relative bg-gray-50">
      <div className="flex z-10 justify-center items-center sticky top-[65px] px-[1em] bg-white shadow-md py-5">
        <input
          type="text"
          placeholder="Search events..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full p-2 border rounded md:w-1/2 lg:mr-[3.5em] lg:w-7/12 text-over-yonder-default border-gray-300 focus:outline-none focus:ring-2 focus:ring-over-yonder-default"
        />
        <div className="relative inline-block">
          <select
            className="appearance-none px-4 py-2 rounded-full border-2 transition-all duration-300 ease-in-out text-sm md:text-base bg-white text-over-yonder-default border-gray-300 focus:outline-none focus:ring-2 focus:ring-over-yonder-default"
            value={selectedTab}
            onChange={(e) => handleTabChange(e.target.value)}
          >
            {[
              { label: "All Posts 📺", value: "All Posts" },
              { label: "Athletics ⚽️", value: "Athletics" },
              { label: "Campus Events", value: "Campus Events" },
              { label: "Good Eats 🥖", value: "Good Eats" },
              { label: "Politics 🗳", value: "Politics" },
              { label: "Night Life 🍻", value: "Night Life" },
              { label: "Greek Life 🏛", value: "Greek Life" },
            ].map((tab) => (
              <option key={tab.value} value={tab.value}>
                {tab.label}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center h-auto">
        {loading ? (
          <LoadingComponents />
        ) : (
          <>
            {overyonder && 
              filteredEvents.map((event_post, key) => {
                let typeDevice = ["Macintosh", "Android", "iPhone", "iPad"];
                let urlGoogle = `https://www.google.com/maps/search/?api=1&query=${event_post.latitude},${event_post.longitude}`;
                let isApple = typeDevice.some((device) => window.navigator.userAgent.includes(device));
                if (isApple) {
                  urlGoogle = `https://maps.apple.com/?ll=${event_post.latitude},${event_post.longitude}&q=${event_post.title}`;
                } else {
                  urlGoogle = `https://www.google.com/maps/search/?api=1&query=${event_post.latitude},${event_post.longitude}`;
                }
                return (
                  <div
                    key={key}
                    className="bg-white shadow-lg rounded-lg overflow-hidden mt-7 mb-10 w-full md:w-1/2 lg:w-3/4"
                  >
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">
                          {event_post?.title}
                        </h2>
                        <span className="md:text-sm text-xs text-center text-gray-500">
                          {readableDate(event_post.start_date)}
                        </span>
                        <Link to={`/user/${event_post.user_profiles.id}`}>
                          <div className="w-[100%] flex flex-row-reverse flex-wrap items-center py-2 lg:py-0 px-2">
                            <img
                              className="h-[40px] w-[40px] rounded-full border cursor-pointer border-over-yonder"
                              src={event_post.user_profiles.avatar_url}
                              alt="avatar"
                              title={
                                event_post.user_profiles.full_name ||
                                `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`
                              }
                            />
                            <p className="mx-2">
                              {event_post.user_profiles.full_name ||
                                `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`}
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="flex md:flex-col-reverse lg:flex-row justify-between">
                        <div className="flex flex-col w-full justify-between">
                          <p className="text-gray-800 text-2xl md:pt-4">
                            {event_post?.description
                              .trim()
                              .replace(
                                /(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g,
                                (matched) => {
                                  let withProtocol = matched;

                                  if (!withProtocol.startsWith("http")) {
                                    withProtocol = "http://" + matched;
                                  }

                                  return `<a
                                    className="text-blue-600 text-base dark:text-blue-500 hover:underline"
                                    href="${withProtocol}" target="_blank"
                                  >
                                    ${matched}
                                  </a>`;
                                }
                              )
                              .split("\\n")
                              .map((item, key) => (
                                <React.Fragment key={key}>
                                  <span
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  ></span>
                                  <br />
                                </React.Fragment>
                              ))}
                          </p>
                          <div className="flex items-center justify-between pt-4 lg:mr-4 border-t">
                            <div className="flex items-center w-full">
                              <button
                                className={`p-2 mx-1 w-fit h-fit border rounded-lg flex justify-center items-center ${
                                  event_post.user_event.filter(
                                    (event) =>
                                      event.user_id === user.id &&
                                      event.event_id === event_post.id
                                  ).length
                                    ? "bg-over-yonder-default text-white"
                                    : ""
                                }`}
                                ref={(el) =>
                                  (calendarContainer.current[key] = el)
                                }
                              >
                                <CalendarIcon
                                  onClick={() => attendEvent(key, event_post)}
                                  ref={(el) =>
                                    (calendarElement.current[key] = el)
                                  }
                                  className={`h-6 w-6 ${
                                    event_post.user_event.filter(
                                      (event) =>
                                        event.user_id === user.id &&
                                        event.event_id === event_post.id
                                    ).length
                                      ? "text-white"
                                      : "text-over-yonder-default"
                                  } `}
                                />
                              </button>

                              <button
                                className={`p-2 w-fit mx-1 h-fit border rounded-lg flex justify-center items-center`}
                              >
                                <ShareIcon
                                  className={`h-6 w-6 text-over-yonder-default`}
                                />
                              </button>
                              <button
                                onClick={() => setUpvote(upvote + 1)}
                                className="p-2 w-fit mx-1 h-fit border rounded-lg flex justify-center items-center"
                              >
                                <HeartIcon className="h-6 w-6 text-over-yonder-default" />
                              </button>
                              <span>{upvote} Likes</span>
                            </div>
                            <Link
                              params={{
                                t: event_post.title,
                                ll: `${event_post.latitude},${event_post.longitude}`,
                              }}
                              target="_blank"
                              to={urlGoogle}
                            >
                              <button
                                className={`p-2 w-fit mx-1 h-fit border rounded-lg flex justify-center items-center`}
                              >
                                <MapPinIcon
                                  className={`h-6 w-6 text-over-yonder-default`}
                                />
                              </button>
                            </Link>
                          </div>
                        </div>

                        <div
                          className={`w-initial h-initial rounded-lg overflow-hidden lg:opacity-100 lg:w-[300px] lg:h-[300px] bg-cover bg-center`}
                          style={{
                            backgroundImage: `url(${event_post.image})`,
                          }}
                        >
                          <img
                            src={`${event_post.image}`}
                            alt="Post Images"
                            className="h-48 w-96 object-cover lg:hidden"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col w-full items-center px-4 py-2 bg-gray-100">
                      <Maps
                        event={event_post.title}
                        desc={event_post.description}
                        lat={event_post.latitude}
                        lng={event_post.longitude}
                      />
                      <Comments event_id={event_post.id} />
                    </div>
                  </div>
                );
              })
            }
            {!!overyonder && filteredEvents.length === 0 && (
              <NoEventsMessage message="No events found" />
            )}
          </>
        )}
      </div>
      {showAttendModal && selectedEvent && (
        <Modal
          isOpen={showAttendModal}
          onClose={() => setShowAttendModal(false)}
          title="Event Added"
        >
          <div className="p-4">
            <p className="mb-4">
              You are now attending "{selectedEvent.title}".
            </p>
            <p className="mb-4">
              Would you like to add this event to your calendar?
            </p>
            <div className="flex flex-row justify-between">
              <button
                onClick={() => addToGoogleCalendar(selectedEvent)}
                className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                <CalendarIcon className="w-5 h-5 mr-2" />
                Google
              </button>
              <button
                onClick={() => addToOutlook(selectedEvent)}
                className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                <CalendarIcon className="w-5 h-5 mr-2" />
                Outlook
              </button>
              <button
                onClick={() => downloadICSFile(selectedEvent)}
                className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                <DocumentArrowDownIcon className="w-5 h-5 mr-2" />
                Download ICS
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showUnsubscribeModal && unsubscribedEvent && (
        <Modal
          isOpen={showUnsubscribeModal}
          onClose={() => setShowUnsubscribeModal(false)}
          title="Event Unsubscribed"
        >
          <div className="p-4">
            <p className="mb-4">
              You have unsubscribed from "{unsubscribedEvent.title}".
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Events;
