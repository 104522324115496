import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supaBaseClient";
import { useAuth } from "../utils/AuthContext";
import LoadingComponents from "../components/LoadingComponents";
import { Link } from "react-router-dom";

function Friends() {
  const { user } = useAuth();
  const [peers, setPeers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userSchool, setUserSchool] = useState(null);
  const [friendships, setFriendships] = useState({});
  const [incomingFriendRequests, setIncomingFriendRequests] = useState([]);
  const [outgoingFriendRequests, setOutgoingFriendRequests] = useState({});

  useEffect(() => {
    async function fetchUserSchoolAndPeers() {
      setLoading(true);

      // Fetch user's school
      const { data: userData, error: userError } = await supabase
        .from("user_profiles")
        .select("schools (*)")
        .eq("id", user.id)
        .single();

      if (userError) {
        console.error("Error fetching user school:", userError);
        setLoading(false);
        return;
      }

      setUserSchool(userData.schools);

      // Fetch peers from the same school
      const { data: peersData, error: peersError } = await supabase
        .from("user_profiles")
        .select(
          `
          id,
          username,
          first_name,
          last_name,
          avatar_url,
          institution_id
        `
        )
        .eq("institution_id", userData.schools.id)
        .neq("id", user.id);

      if (peersError) {
        console.error("Error fetching peers:", peersError);
      } else {
        setPeers(peersData);
      }

      // Fetch friendships
      const { data: friendshipsData, error: friendshipsError } = await supabase
        .from("user_friends")
        .select("*")
        .or(`requester_id.eq.${user.id},addressee_id.eq.${user.id}`)
        .eq("accepted_request", true);

      if (friendshipsError) {
        console.error("Error fetching friendships:", friendshipsError);
      } else {
        const friendshipStatus = {};
        friendshipsData.forEach((friendship) => {
          const friendId =
            friendship.requester_id === user.id
              ? friendship.addressee_id
              : friendship.requester_id;
          friendshipStatus[friendId] = true;
        });
        setFriendships(friendshipStatus);
      }

      // Fetch incoming friend requests
      const { data: incomingRequestsData, error: incomingRequestsError } =
        await supabase
          .from("user_friends")
          .select("*, user_profiles!user_friends_requester_id_fkey(*)")
          .eq("addressee_id", user.id)
          .eq("accepted_request", false);

      if (incomingRequestsError) {
        console.error(
          "Error fetching incoming friend requests:",
          incomingRequestsError
        );
      } else {
        setIncomingFriendRequests(incomingRequestsData);
      }

      // Fetch outgoing friend requests
      const { data: outgoingRequestsData, error: outgoingRequestsError } =
        await supabase
          .from("user_friends")
          .select("addressee_id")
          .eq("requester_id", user.id)
          .eq("accepted_request", false);

      if (outgoingRequestsError) {
        console.error(
          "Error fetching outgoing friend requests:",
          outgoingRequestsError
        );
      } else {
        const outgoingRequests = {};
        outgoingRequestsData.forEach((request) => {
          outgoingRequests[request.addressee_id] = true;
        });
        setOutgoingFriendRequests(outgoingRequests);
      }

      setLoading(false);
    }

    fetchUserSchoolAndPeers();
  }, [user.id]);

  const sendFriendRequest = async (friendId) => {
    const { data, error } = await supabase.from("user_friends").insert({
      requester_id: user.id,
      addressee_id: friendId,
    });

    if (error) {
      console.error("Error sending friend request:", error);
    } else {
      // Update local state to show pending request
      setOutgoingFriendRequests({
        ...outgoingFriendRequests,
        [friendId]: true,
      });
    }
  };

  const acceptFriendRequest = async (requestId) => {
    const { data, error } = await supabase
      .from("user_friends")
      .update({ accepted_request: true })
      .eq("id", requestId);

    if (error) {
      console.error("Error accepting friend request:", error);
    } else {
      // Update local state
      const acceptedRequest = incomingFriendRequests.find(
        (request) => request.id === requestId
      );
      setIncomingFriendRequests(
        incomingFriendRequests.filter((request) => request.id !== requestId)
      );
      setFriendships({ ...friendships, [acceptedRequest.requester_id]: true });
    }
  };

  if (loading) {
    return <LoadingComponents />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-2 text-center text-purple-800">
        School Students
      </h1>
      <p className="mb-6 text-center text-gray-600">
        Students from {userSchool?.college_name}
      </p>

      {incomingFriendRequests.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Friend Requests</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {incomingFriendRequests.map((request) => (
              <div
                key={request.id}
                className="bg-white shadow rounded-lg p-4 flex items-center justify-between"
              >
                <div className="flex items-center">
                  <img
                    src={
                      request.user_profiles.avatar_url || "default-avatar.png"
                    }
                    alt={`${request.user_profiles.first_name} ${request.user_profiles.last_name}`}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-semibold">
                      {request.user_profiles.first_name}{" "}
                      {request.user_profiles.last_name}
                    </p>
                    <p className="text-sm text-gray-600">
                      @{request.user_profiles.username}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => acceptFriendRequest(request.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-green-600 transition-colors duration-300"
                >
                  Accept
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {peers.map((peer) => (
          <Link to={`/user/${peer.id}`}>
            <div
              key={peer.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105"
            >
              <div className="h-32 bg-gradient-to-r from-purple-500 to-indigo-600"></div>
              <div className="relative px-6 py-4">
                <img
                  src={peer.avatar_url || "default-avatar.png"}
                  alt={`${peer.first_name} ${peer.last_name}`}
                  className="w-24 h-24 rounded-full absolute -top-12 left-1/2 transform -translate-x-1/2 border-4 border-white shadow-lg"
                />
                <div className="mt-12 text-center">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {peer.first_name} {peer.last_name}
                  </h2>
                  <p className="text-purple-600 font-medium">
                    @{peer.username}
                  </p>
                </div>
                <div className="mt-4 flex justify-center">
                  {friendships[peer.id] === true ? (
                    <span className="bg-green-500 text-white px-4 py-2 rounded-full text-sm font-medium">
                      Friends
                    </span>
                  ) : outgoingFriendRequests[peer.id] ? (
                    <span className="bg-yellow-500 text-white px-4 py-2 rounded-full text-sm font-medium">
                      Request Sent
                    </span>
                  ) : (
                    <button
                      onClick={() => sendFriendRequest(peer.id)}
                      className="bg-purple-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-purple-700 transition-colors duration-300"
                    >
                      Add Friend
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Friends;
