import { useState, useEffect } from "react";
import { supabase } from "../utils/supaBaseClient";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import { PencilIcon } from "@heroicons/react/24/solid";
import { toCapitalCase } from "../utils/Helper";
import Modal from "../features/Modal";

export default function Account() {
  const { user, logoutUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [bio, setBio] = useState("No bio yet.");
  const [avatar_url, setAvatarUrl] = useState(null);
  const navigate = useNavigate();
  const [school, setSchool] = useState(null);
  const [show, setShow] = useState(false);
  const [yonderCount, setYonderCount] = useState(0);
  const [yonderedCount, setYonderedCount] = useState(0);
const [friends, setFriends] = useState([]);
const [showFriends, setShowFriends] = useState(false);
async function getFriends() {
  const { data, error } = await supabase
    .from("user_friends")
    .select(
      `
      *,
      requester:requester_id(id, username, first_name, last_name, avatar_url),
      addressee:addressee_id(id, username, first_name, last_name, avatar_url)
    `
    )
    .or(`requester_id.eq.${user.id},addressee_id.eq.${user.id}`)
    .eq("accepted_request", true);

  if (error) {
    console.error("Error fetching friends:", error);
  } else {
    const friendsList = data.map((friendship) => {
      // If the current user is the requester, return the addressee as the friend
      if (friendship.requester_id === user.id) {
        return friendship.addressee;
      }
      // If the current user is the addressee, return the requester as the friend
      return friendship.requester;
    });
    setFriends(friendsList);
  }
}



  useEffect(() => {
    async function getProfile() {
      setLoading(true);

      let { data, error } = await supabase
        .from("user_profiles")
        .select(
          `username, first_name, last_name, website, avatar_url, bio, schools (short_hand_name,primary_color,secondary_color)`
        )
        .eq("id", user.id)
        .single();

      if (error) {
        console.warn(error);
      } else if (data) {
        console.log(data)
        setUsername(data.username);
        setWebsite(data.website);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setAvatarUrl(data.avatar_url);
        data.bio && setBio(data.bio);
        setSchool(data.schools);
      }

      // Get count of events user is attending
      let { count: attendingCount, error: attendingError } = await supabase
        .from("user_event")
        .select("*", { count: "exact" })
        .eq("user_id", user.id);

      if (!attendingError) setYonderCount(attendingCount);

      // Get count of events user has created
      let { count: createdCount, error: createdError } = await supabase
        .from("event_post")
        .select("*", { count: "exact" })
        .eq("created_by", user.id);

      if (!createdError) setYonderedCount(createdCount);

      setLoading(false);
    }

    getProfile();
    getFriends();
  }, [user]);

  async function updateProfile(event) {
    event.preventDefault();
    setLoading(true);

    const updates = {
      username: username,
      website: website,
      bio: bio,
      first_name: first_name,
      last_name: last_name,
      updated_at: new Date(),
    };

    const { data, error } = await supabase
      .from("user_profiles")
      .update(updates)
      .eq("id", user.id)
      .select();

    if (error) {
      alert(error.message);
    }

    setLoading(false);
    setShow(false);
  }

  async function handleLogOut() {
    try {
      logoutUser();
      navigate("/");
    } catch (error) {
      alert(error.error_description || error.message);
    }
  }

  return (
    <div className="bg-white rounded-lg shadow p-4 md:p-6">
      <div className="flex flex-col md:flex-row justify-evenly items-center mb-6">
        <div className="relative mb-4 md:mb-0">
          <div className="w-32 h-32 md:w-40 md:h-40 overflow-hidden rounded-full border relative">
            <img
              src={avatar_url}
              alt="Avatar"
              className="object-cover w-full h-full"
            />
            {school && (
              <div
                className={`absolute inset-0 border-4 rounded-full flex items-center justify-center`}
                style={{ borderColor: school.primary_color }}
              >
                <span
                  className="text-xs px-2 py-1 rounded-full absolute -bottom-2 left-1/2 transform -translate-x-1/2"
                  style={{
                    backgroundColor: school.primary_color,
                    color: school.secondary_color,
                  }}
                >
                  {school.short_hand_name}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="ml-0 w-[500px] md:ml-4 text-center md:text-left">
          <h2 className="text-xl font-semibold">{`${toCapitalCase(
            first_name
          )} ${toCapitalCase(last_name)}`}</h2>
          <p className="text-gray-500">@{username}</p>
          <p className="w-full text-sm text-gray-400">{bio}</p>
          {website && (
            <a
              href={website.startsWith("http") ? website : `https://${website}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-800 text-sm"
            >
              {website}
            </a>
          )}
        </div>

        <div className="flex flex-col mt-4 md:mt-0 md:ml-4">
          <button
            onClick={() => setShow(true)}
            style={{ backgroundColor: "#745296" }}
            className="inline button px-6 py-3 mb-2 rounded-lg text-white"
          >
            Edit{" "}
            <PencilIcon className="inline h-4 w-4 text-white-600 leading-7 cursor-pointer" />
          </button>
          <button
            className="button px-6 py-3 rounded-lg text-white"
            type="button"
            onClick={handleLogOut}
            style={{ backgroundColor: "#745296" }}
          >
            Sign Out
          </button>
        </div>
      </div>

      <div className="w-full my-10">
        <div className="flex flex-row justify-evenly text-center">
          <div className="flex flex-col">
            <span className="font-semibold">{yonderCount}</span> Yonder
          </div>
          <div className="flex flex-col">
            <span className="font-semibold">{yonderedCount}</span> Yondered
          </div>
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setShowFriends(!showFriends)}
          >
            <span className="font-semibold">{friends.length}</span> Friends
          </div>

          <div className="flex flex-col">
            <span className="font-semibold">0</span> Liked
          </div>
        </div>
      </div>

      <Modal isOpen={show} onClose={() => setShow(false)} title="Edit Profile">
        <form onSubmit={updateProfile} className="space-y-6 p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                First Name
              </label>
              <input
                id="firstname"
                type="text"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Last Name
              </label>
              <input
                id="lastname"
                type="text"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email
            </label>
            <input
              id="email"
              type="text"
              value={user.email}
              disabled
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
            />
          </div>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Username
            </label>
            <input
              id="username"
              type="text"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              placeholder="Your Username"
              value={username || ""}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="website"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Website
            </label>
            <input
              id="website"
              type="url"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              placeholder="https://example.com"
              value={website || ""}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="bio"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Bio
            </label>
            <textarea
              id="bio"
              rows="4"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              placeholder="Tell us about yourself"
              value={bio || ""}
              onChange={(e) => setBio(e.target.value)}
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setShow(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              {loading ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </form>
      </Modal>
      {showFriends && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">Friends List</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {friends.map((friend) => (
              <div
                key={friend.id}
                className="flex items-center space-x-3 p-2 bg-gray-100 rounded-lg"
              >
                <img
                  src={friend.avatar_url || "default-avatar.png"}
                  alt={`${friend.first_name} ${friend.last_name}`}
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <p className="font-medium">
                    {friend.first_name} {friend.last_name}
                  </p>
                  <p className="text-sm text-gray-600">@{friend.username}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
