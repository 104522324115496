import React, { useState, useEffect } from "react";
import { RangeCalendar } from "../components/RangeCalendar";
import { today, getLocalTimeZone, parseDate } from "@internationalized/date";
import { supabase } from "../utils/supaBaseClient";

function Calendar() {
  const [dateRange, setDateRange] = useState({
    start: today(getLocalTimeZone()),
    end: today(getLocalTimeZone()),
  });
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents(dateRange);
  }, [dateRange]);

  const fetchEvents = async (range) => {
    // Convert start date to local date string, then create a new Date object
    const startDate = new Date(
      range.start.toDate(getLocalTimeZone()).toDateString()
    );

    // Convert end date to local date string, then create a new Date object and set it to the end of the day
    const endDate = new Date(
      range.end.toDate(getLocalTimeZone()).toDateString()
    );
    endDate.setHours(23, 59, 59, 999);

    console.log(
      "Fetching events from",
      startDate.toISOString(),
      "to",
      endDate.toISOString()
    );

    let { data, error } = await supabase
      .from("event_post")
      .select("*")
      .gte("start_date", startDate.toISOString())
      .lte("start_date", endDate.toISOString())
      .order("start_date", { ascending: true });

    if (error) {
      console.error("Error fetching events:", error);
    } else {
      setEvents(data || []);
      console.log("Fetched events:", data);
    }
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const formatDateRange = (range) => {
    const start = range.start.toDate(getLocalTimeZone());
    const end = range.end.toDate(getLocalTimeZone());
    return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
  };

  const formatEventDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="flex flex-wrap justify-around px-4 pt-4 row-span-4 h-full relative flex-row">
      <RangeCalendar
        className="w-fit h-fit sticky top-0"
        minValue={today(getLocalTimeZone())}
        defaultValue={dateRange}
        onChange={handleDateRangeChange}
      />
      <div className="h-auto pt-4 w-[50%]">
        <h1 className="p-2 text-xl font-bold">
          Events for {formatDateRange(dateRange)}
        </h1>
        <div className="px-2">
          {events.length > 0 ? (
            <ul>
              {events.map((event) => (
                <li key={event.id} className="mb-4 p-2 border rounded">
                  <h3 className="font-bold">{event.title}</h3>
                  <p>{formatEventDate(event.start_date)}</p>
                  <p>{event.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No events for this date range.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Calendar;
