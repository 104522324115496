import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Sidebar({ SidebarOpen, setSidebarOpen }) {
  const sidebarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    if (SidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [SidebarOpen, setSidebarOpen]);

  return (
    <div
      ref={sidebarRef}
      className={`${
        SidebarOpen ? "block" : "hidden"
      } lg:block bg-white z-50 transition-transform`}
    >
      <nav className="custom-nav bg-white flex flex-col fixed top-[65px] left-0 w-[200px] justify-between row-span-4 shadow-lg">
        <ul className="h-3/6 flex flex-col content-around flex-wrap justify-around items-center">
          {[
            { label: "Home", path: "/home" },
            { label: "Calendar", path: "/calendar" },
            { label: "Friends", path: "/friends" },
            { label: "Account", path: "/me" },
          ].map((item) => (
            <li key={item.path} className="w-36">
              <Link
                className="block border border-over-yonder p-3 w-full text-center hover:bg-over-yonder-default hover:text-white transition-colors duration-300"
                to={item.path}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="h-28 flex justify-center content-center flex-wrap">
          <Link
            className="border border-over-yonder p-3 w-36 text-center hover:bg-over-yonder-default hover:text-white transition-colors duration-300"
            to="/create"
          >
            New Event
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
