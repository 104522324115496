import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../utils/supaBaseClient";
import { useAuth } from "../utils/AuthContext";

const User = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [other, setUser] = useState({});
  const [friendshipStatus, setFriendshipStatus] = useState(null);
  const { avatar_url, first_name, full_name, last_name, username, bio } = other;

  const getUser = async () => {
    let { data, error } = await supabase
      .from("user_profiles")
      .select("*")
      .eq("id", id);
    if (error || data.length === 0) {
      navigate("/404");
    } else {
      setUser(data[0]);
    }
  };

  const getFriendshipStatus = async () => {
    let { data, error } = await supabase
      .from("user_friends")
      .select("*")
      .or(`requester_id.eq.${user.id},addressee_id.eq.${user.id}`)
      .or(`requester_id.eq.${id},addressee_id.eq.${id}`)
      .single();

    if (error) {
      console.error("Error fetching friendship status:", error);
      setFriendshipStatus("not_friends");
    } else if (data) {
      if (data.accepted_request) {
        setFriendshipStatus("friends");
      } else if (data.requester_id === user.id) {
        setFriendshipStatus("request_sent");
      } else {
        setFriendshipStatus("request_received");
      }
    } else {
      setFriendshipStatus("not_friends");
    }
  };

  useEffect(() => {
    if (user.id === id) {
      navigate("/me");
    } else {
      getUser();
      getFriendshipStatus();
    }
  }, [user, id, navigate]);

  const renderFriendshipStatus = () => {
    switch (friendshipStatus) {
      case "friends":
        return (
          <span className="bg-green-500 text-white px-4 py-2 rounded-full">
            Friends
          </span>
        );
      case "request_sent":
        return (
          <span className="bg-yellow-500 text-white px-4 py-2 rounded-full">
            Friend Request Sent
          </span>
        );
      case "request_received":
        return (
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-full"
            onClick={acceptFriendRequest}
          >
            Accept Friend Request
          </button>
        );
      case "not_friends":
        return (
          <button
            className="bg-purple-600 text-white px-4 py-2 rounded-full"
            onClick={sendFriendRequest}
          >
            Add Friend
          </button>
        );
      default:
        return null;
    }
  };

  const sendFriendRequest = async () => {
    const { error } = await supabase
      .from("user_friends")
      .insert({ requester_id: user.id, addressee_id: id });

    if (error) {
      console.error("Error sending friend request:", error);
    } else {
      setFriendshipStatus("request_sent");
    }
  };

  const acceptFriendRequest = async () => {
    const { error } = await supabase
      .from("user_friends")
      .update({ accepted_request: true })
      .match({ requester_id: id, addressee_id: user.id });

    if (error) {
      console.error("Error accepting friend request:", error);
    } else {
      setFriendshipStatus("friends");
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex flex-col md:flex-row justify-evenly items-center mb-6">
        <div className="relative">
          <div className="w-40 h-40 overflow-hidden rounded-full border">
            <img
              src={avatar_url}
              alt="Avatar"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
        <div className="ml-4 ">
          {first_name && last_name ? (
            <h2 className="text-xl font-semibold">{`${first_name} ${last_name}`}</h2>
          ) : (
            <h2 className="text-xl font-semibold">{full_name}</h2>
          )}
          <p className="text-gray-500">@{username}</p>
          <p className="w-full text-sm text-gray-400">{bio}</p>
        </div>
        <div className="flex flex-col md:justify-start ml-4 justify-center">
          {renderFriendshipStatus()}
        </div>
      </div>

      <div className="w-full justify-center my-10">
        <div className="flex flex-row justify-evenly">
          <div className=" flex justify-center flex-col">
            <span className="font-semibold text-center">0</span> Yonder
          </div>
          <div className="flex justify-center flex-col">
            <span className="font-semibold text-center">0</span> Yondered
          </div>
          <div className="flex justify-center flex-col">
            <span className="font-semibold text-center">0</span> Friends
          </div>
          <div className="flex justify-center flex-col">
            <span className="font-semibold text-center">0</span> Liked
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
