import {
  AcademicCapIcon,
  GlobeAltIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { NewLocationMaps } from "../components/Maps";
import { supabase } from "../utils/supaBaseClient";
import { useAuth } from "../utils/AuthContext";
import { convertJPGCompress } from "../utils/imageUtils";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const NewEvent = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [startDateandTime, setStartDateandTime] = useState(new Date());
  const [endDateandTime, setEndDateandTime] = useState(new Date());
  const [wait, setWait] = useState(false);

  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [privacy, setPrivacy] = useState(1);
  const [category, setCategory] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [latitude, setLatitude] = useState(43.6531602);
  const [longitude, setLongitude] = useState(-116.6748223);
  const [error, setError] = useState({
    on: false,
    message: "",
  });
  const [compressedImageUrl, setCompressedImageUrl] = useState();

  const { user } = useAuth();
  const [schoolId, setSchoolId] = useState();
  const [eventData, setEventData] = useState({
    title: eventTitle,
    description: description,
    start_date: startDateandTime,
    end_date: endDateandTime,
    created_by: user?.id,
    latitude: latitude,
    longitude: longitude,
    address: "NONE",
    category: category,
    image: compressedImageUrl,
    institution: schoolId,
    is_public: privacy === 0 || privacy === 1 ? true : false,
  });

  const [uploadStatus, setUploadStatus] = useState("");
  const [location, setLocation] = useState({});
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  async function success(pos) {
    var crd = await pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setLocation(crd);
  }
  const file_input = useRef(null);

  function handleFileChange(e) {
    const REQUIRED_WIDTH = 1000; // Example width in pixels
    const REQUIRED_HEIGHT = 1000; // Example height in pixels
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;

          if (width >= REQUIRED_WIDTH && height >= REQUIRED_HEIGHT) {
            setImage(file);
            // Proceed with the upload or any other logic here
          } else {
            setImage(null);
            setError({
              ...error,
              on: true,
              message: `Image must be ${REQUIRED_WIDTH}px wide and ${REQUIRED_HEIGHT}px tall.`,
            });
            setTimeout(() => {
              setError({
                ...error,
                on: false,
              });
            }, 5000);
            if (file_input.current) {
              file_input.current.value = ""; // This clears the file input
            }
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  const handleCompressAndUpload = async () => {
    if (!image) {
      setError({
        ...error,
        on: true,
        message: "Upload an Image...",
      });
      setTimeout(() => {
        setError({
          ...error,
          on: false,
        });
      }, 5000);
      return null;
    }
    const filePath = `${user?.id}/${uuidv4()}.jpg`;

    try {
      const converted = await convertJPGCompress(image);

      const formData = new FormData();
      formData.append("file", converted);

      const { data, err } = await supabase.storage
        .from("event_images")
        .upload(filePath, formData);
      if (err) {
        setError({
          ...error,
          on: true,
          message: "Error uploading an image...",
        });
        setTimeout(() => {
          setError({
            ...error,
            on: false,
          });
        }, 5000);
        console.error(err);
        return null;
      }
      const imageUrl =
        "https://ytkcetpnidmsrxmzdamk.supabase.co/storage/v1/object/public/event_images/" +
        filePath;
      setCompressedImageUrl(imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error compressing the image", error);
      setError({
        ...error,
        on: true,
        message: "Error uploading an image.  Must select an image to upload...",
      });
      setTimeout(() => {
        setError({
          ...error,
          on: false,
        });
      }, 5000);
      return null;
    }
  };

  const audiences = [
    {
      title: "🌐 Anyone Around Me",
      context: "Anyone in a 5 mile radius of the event can see.",
      data: { is_public: true, institution_id: null },
    },
    {
      title: "🎓 Just My School",
      context: "Only students in your school can see this event.",
      data: {
        is_public: true,
        institution_id: schoolId,
      },
    },
    {
      title: "Just Me (Private)",
      context: "Only you can see this event.",
      data: { is_public: false, institution_id: null },
    },
  ];

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    async function getSchoolId() {
      if (!schoolId) {
        let { data, err } = await supabase
          .from("user_profiles")
          .select("schools(*)")
          .eq("id", user?.id)
          .single();
        if (data) {
          setSchoolId(data.schools.id);
          console.log(data);
        }
        if (err) {
          setError({
            ...error,
            on: true,
            message:
              "Error getting your school id. Refresh page and try again...",
          });
          setTimeout(() => {
            setError({
              ...error,
              on: false,
            });
          }, 5000);
        }
      }
    }
    getSchoolId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
            setLatitude(location.latitude);
            setLongitude(location.longitude);
            getUserCurrentLocation();
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            success({
              coords: {
                latitude: 40.7128,
                longitude: -74.006,
                accuracy: 0,
              },
            });
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserCurrentLocation = async () => {
    const { data, error } = await supabase
      .from("user_last_known_location")
      .upsert({ user_id: user.id, latitude: latitude, longitude: longitude })
      .select();
    console.log(data || error);
    console.log("work", user.id);
  };

  useEffect(() => {
    let fullStart = new Date(startDate + " " + startTime);
    let fullEnd = new Date(endDate + " " + endTime);
    setStartDateandTime(fullStart);
    setEndDateandTime(fullEnd);
  }, [startDate, endDate, startTime, endTime]);

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    setWait(true);
    setUploadStatus("Uploading event...");
    try {
      const imageUrl = await handleCompressAndUpload();
      if (!imageUrl) {
        // Handle the case where image upload failed
        setError({
          ...error,
          on: true,
          message: "Image failed to upload. Refresh and try again...",
        });
        setTimeout(() => {
          setError({
            ...error,
            on: false,
          });
        }, 5000);
        setWait(false);
        return;
      }

      if (startDateandTime > endDateandTime && Date.now() < startDateandTime) {
        setError({
          ...error,
          on: true,
          message: "Start date and time must be before end date and time",
        });
        setTimeout(() => {
          setError({
            ...error,
            on: false,
          });
        }, 5000);
        setWait(false);
        return;
      }

      const updatedEventData = {
        title: eventTitle || null,
        description: description || null,
        image: imageUrl,
        category: category ? parseInt(category) : null,
        start_date: startDateandTime.toISOString(),
        end_date: endDateandTime.toISOString(),
        created_by: user?.id,
        institution: schoolId || null,
        address: "TBA", // Default value as per table structure
        latitude: latitude || 0.0,
        longitude: longitude || 0.0,
        is_public: audiences[privacy].data.is_public,
        // Removed fields that have default values in the database
        // like_count, is_advertisement
      };

      setEventData(updatedEventData);
      await loadEventData(updatedEventData);
    } catch (err) {
      console.error(err);
      setWait(false);
      setError({
        ...error,
        on: true,
        message: "Error uploading and compressing image...",
      });
      setTimeout(() => {
        setError({
          ...error,
          on: false,
        });
      }, 5000);
      return;
    }

    async function loadEventData(d) {
      console.log(d);
      let { data, err } = await supabase.from("event_post").insert([d]);

      if (err) {
        setError({
          ...error,
          on: true,
          message: "Event did not get posted. Refresh and try again...",
        });
        setTimeout(() => {
          setError({
            ...error,
            on: false,
          });
        }, 5000);
        setWait(false);

        return;
      }
      console.log(data);
      setUploadStatus("Event uploaded successfully!");
      setTimeout(() => {
        navigate("/home");
      }, 3000); // Redirect after 2 seconds
    }
  };

  return (
    <div className="">
      <div
        className={`bg-red-100 border ${
          error.on ? "block" : "hidden"
        } border-red-400 text-red-700 px-4 py-3 rounded w-auto fixed bottom-0 right-0 z-50`}
        role="alert"
      >
        <strong className="font-bold">Holy smokes!</strong>
        <span className="block sm:block">{error.message}</span>
        <span
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
          onClick={() => setError(!error)}
        >
          <svg
            className="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
      {uploadStatus && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded w-auto fixed bottom-0 right-0 z-50">
          <strong className="font-bold">{uploadStatus}</strong>
        </div>
      )}
      <h1 className="text-lg font-bold text-gray-700 leading-tight text-center mt-12 mb-5">
        Create Event
      </h1>
      <form
        id="signUpForm"
        className="p-12 shadow-md rounded-2xl bg-white mx-auto border-solid border-2 border-gray-100 mb-8"
        action="#!"
        onSubmit={handleEventSubmit}
      >
        <div className="step">
          <p className="text-md text-gray-700 leading-tight text-center mt-8 mb-5">
            Event Details
          </p>
          <div className="mb-6">
            <input
              type="Text"
              placeholder="Title of Event"
              onChange={(e) => setEventTitle(e.target.value)}
              name="eventTitle"
              className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
              //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
            />
          </div>
          <div className="mb-6">
            <input
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              name="description"
              className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
              //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
            />
          </div>
          <div className="mb-6">
            <select
              placeholder="Select Category"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
              required
              className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
              // //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
            >
              <option value="">Select Category</option>
              <option value="2">Campus Events</option>
              <option value="1">Athletics</option>
              <option value="3">Good Eats</option>
              <option value="4">Night life</option>
              <option value="5">Politics</option>
              <option value="6">Greek Life</option>
            </select>
          </div>
          <div className="mb-6">
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              required
              ref={file_input}
              accept="image/png, image/jpeg, image/jpg"
              placeholder="Location"
              name="location"
              className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
              // //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
            />
            <p>Choose new Image</p>
          </div>
        </div>

        <div className="step">
          <p className="text-md text-gray-700 leading-tight text-center mt-8 mb-5">
            Privacy
          </p>
          <fieldset className="">
            <div className="mb-6">
              <label className="w-full px-4 py-3 inline-flex justify-between rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200">
                <div className="w-full inline-flex">
                  <GlobeAltIcon className=" h-6 w-6" />
                  <span className="ml-2">Anyone Around Me</span>
                </div>

                <input
                  type="radio"
                  onChange={(e) => setPrivacy(0)}
                  placeholder="Anyone Around Me"
                  name="privacy"
                  className="inline-block ml-5"
                  // className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                  //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
                />
              </label>
            </div>
            <div className="mb-6">
              <label className="w-full px-4 py-3 inline-flex justify-between rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200">
                <div className="w-full inline-flex">
                  <AcademicCapIcon className=" h-6 w-6" />
                  <span className="ml-2">Just My School</span>
                </div>

                <input
                  type="radio"
                  onChange={(e) => setPrivacy(1)}
                  placeholder=" Just My School"
                  name="privacy"
                  className="inline-block ml-5"
                  // className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                  //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
                />
              </label>
            </div>
            <div className="mb-6">
              <label className="w-full px-4 py-3 inline-flex justify-between rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200">
                <div className="w-full inline-flex">
                  <UserIcon className=" h-6 w-6" />
                  <span className="ml-2">Just Me (Private)</span>
                </div>

                <input
                  type="radio"
                  onChange={(e) => setPrivacy(2)}
                  placeholder="Just Me"
                  name="privacy"
                  className="inline-block ml-5"
                  // className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                  //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
                />
              </label>
            </div>
          </fieldset>
          <p
            className=" 
          text-md text-gray-700 leading-tight text-center mt-8 mb-5"
          >
            Location
          </p>
          <fieldset className="">
            {/* <div className="mb-6">
              <label className="w-full px-4 py-3 inline-flex justify-between rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200">
                <div className="w-full inline-flex">
                  <GlobeAltIcon className=" h-6 w-6" />
                  <span className="ml-2">Somewhere off campus</span>
                </div>

                <input
                  type="radio"
                  onChange={(e) => setLocation1(e.target.value)}
                  placeholder="Anyone Around Me"
                  name="location"
                  className="inline-block ml-5"
                  // className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                  //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
                />
              </label>
            </div>
            <div className="mb-6">
              <label className="w-full px-4 py-3 inline-flex justify-between rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200">
                <div className="w-full inline-flex">
                  <AcademicCapIcon className=" h-6 w-6" />
                  <span className="ml-2">Somewhere around campus</span>
                </div>

                <input
                  type="radio"
                  onChange={(e) => setLocation1(e.target.value)}
                  placeholder=" Just My School"
                  name="location"
                  className="inline-block ml-5"
                  // className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                  //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
                />
              </label> 
            </div>*/}
            <NewLocationMaps
              setLat={setLatitude}
              setLon={setLongitude}
              event={eventTitle}
              lat={eventData.latitude}
              lng={eventData.longitude}
            />
          </fieldset>
        </div>

        <div className="step">
          <p className="text-md text-gray-700 leading-tight text-center mt-8 mb-5">
            Select Date and Time
          </p>
          <div className="mb-6">
            <p>Start Date and Time</p>
            <div className="inline-flex w-full">
              <input
                type="date"
                placeholder="Select Start Date"
                name="startdate"
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                // //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
              />
              <input
                type="time"
                placeholder="Select Time Date"
                onChange={(e) => setStartTime(e.target.value)}
                name="starttime"
                className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                // //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
              />
            </div>
          </div>
          <div className="mb-6">
            <p>End Date and Time</p>
            <div className="inline-flex w-full">
              <input
                type="date"
                placeholder="Select Start Date"
                name="enddate"
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
              />
              <input
                type="time"
                placeholder="Select End Date"
                name="endtime"
                onChange={(e) => setEndTime(e.target.value)}
                className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
                // //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
              />
            </div>
          </div>
          {/* <div className="mb-6">
            <input
              type="text"
              placeholder="Address"
              name="address"
              className="w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200"
              //oninput="this.className = 'w-full px-4 py-3 rounded-md text-gray-700 font-medium border-solid border-2 border-gray-200'"
            />
          </div> */}
        </div>

        <div className="form-footer flex gap-3">
          <button
            type="submit"
            id="nextBtn"
            disabled={wait}
            className={`flex-1 border border-transparent focus:outline-none p-3 rounded-md text-center text-white bg-indigo-600 hover:bg-indigo-700 text-lg
              ${wait ? "bg-gray-400 text-gray-700 cursor-not-allowed" : ""}
              `}
          >
            Create Event
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewEvent;
