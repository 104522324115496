import OneSignal from "react-onesignal";

export const initOneSignal = async () => {

  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_API_KEY, // Replace with your OneSignal App ID
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.showSlidedownPrompt({
    requireInteraction: true,
    autoPrompt: true,
    promptOnFocus: false,
    prePromptHeading: "Enable notifications",
    prePromptContent: "We'd like to send you push notifications about your events.",
    acceptButtonText: "Enable",
    cancelButtonText: "Ask me later",
  });
};
