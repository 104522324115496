import React from 'react'

const LoadingComponents = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-7 mb-10 w-full md:w-1/2 lg:w-3/4 animate-pulse">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          {/* Title and date skeleton */}
          <div className="h-6 bg-gray-200 rounded w-1/4"></div>
          <div className="h-4 bg-gray-200 rounded w-24"></div>
          {/* User profile skeleton */}
          <div className="w-auto flex flex-row-reverse flex-wrap items-center py-2 lg:py-0 px-2">
            <div className="h-[40px] w-[40px] rounded-full bg-gray-200"></div>
            <div className="h-4 bg-gray-200 rounded w-32 mx-2"></div>
          </div>
        </div>

        <div className="flex md:flex-col-reverse lg:flex-row justify-between">
          <div className="flex flex-col w-full justify-between">
            {/* Description skeleton */}
            <div className="space-y-3">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>

            {/* Action buttons skeleton */}
            <div className="flex items-center justify-between pt-4 lg:mr-4 border-t mt-4">
              <div className="flex items-center w-full gap-2">
                {/* Calendar, Share, Heart buttons skeleton */}
                {[...Array(3)].map((_, i) => (
                  <div
                    key={i}
                    className="p-2 w-10 h-10 border rounded-lg bg-gray-200"
                  ></div>
                ))}
                <div className="h-4 bg-gray-200 rounded w-16"></div>
              </div>
              {/* Map pin button skeleton */}
              <div className="p-2 w-10 h-10 border rounded-lg bg-gray-200"></div>
            </div>
          </div>

          {/* Image skeleton */}
          <div className="w-initial h-initial rounded-lg overflow-hidden lg:w-[300px] lg:h-[300px] bg-gray-200">
            <div className="h-48 w-96 lg:hidden bg-gray-200"></div>
          </div>
        </div>
      </div>

      {/* Map and comments section skeleton */}
      <div className="flex md:flex-row flex-col w-full items-center px-4 py-2 bg-gray-50">
        <div className="w-full h-[200px] bg-gray-200 rounded-lg"></div>
        <div className="w-full h-[200px] md:mt-0 md:ml-4 space-y-2">
          <div className="h-10 bg-gray-200 rounded w-full"></div>
          <div className="h-[102px] bg-gray-200 rounded w-full"></div>
          <div className="w-auto flex flex-row-reverse items-center py-2 lg:py-0">
            <div className="h-10 w-1/4 rounded-md bg-gray-200"></div>
            <div className="h-10 w-3/4 bg-gray-200 rounded mr-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingComponents
