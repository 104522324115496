import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supaBaseClient";
import { useAuth } from "../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const Onboarding = () => {
  const accountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
  const authToken = process.env.REACT_APP_TWILIO_AUTH_TOKEN;
  // const client = twilio(accountSid, authToken);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    username: "",
    role: "",
    website: "",
    avatar: null,
    email: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);

  useEffect(() => {
    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from("user_profiles")
        .select(
          "first_name, last_name, bio, username, role_id, website, avatar_url"
        )
        .eq("id", user.id)
        .single();

      if (data) {
        setProfileData({
          firstName: data.first_name || "",
          lastName: data.last_name || "",
          bio: data.bio || "",
          username: data.username || "",
          role: data.role_id ? data.role_id.toString() : "",
          website: data.website || "",
          avatar: data.avatar_url || null,
          email: data.email || "",
        });
      }
      if (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfile();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const [verificationSent, setVerificationSent] = useState(false);

  const handleVerifyEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(user.email);
    // try {
    //    const verification = await client.verify.v2
    //      .services("VA7bd2d144d69c62733ff2f2744735d36b")
    //      .verifications.create({
    //        channel: "email",
    //        channelConfiguration: {
    //          template_id: " d-b1f94ae0ec04406ca3631b75e8989143",
    //          from: "noreply@overyonder.app",
    //          from_name: "OverYonder",
    //        },
    //        to: profileData.email,
    //      });
    //   if (error) throw error;
    //   setVerificationSent(true);
    // } catch (error) {
    //   setError("Error sending verification email. Please try again.");
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  const handleVerifyOtp = async () => {
    // try {
    //    const verificationCheck = await client.verify.v2
    //      .services("VA7bd2d144d69c62733ff2f2744735d36b")
    //      .verificationChecks.create({
    //        code: "123456",
    //        to: profileData.email,
    //      });
    //   if (error) throw error;
    //   setOtpVerified(true);
    // } catch (error) {
    //   setError("Error verifying OTP. Please try again.");
    // }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      setLoading(true);
      const { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file);

      if (uploadError) {
        setError("Error uploading file");
        setLoading(false);
        return;
      }

      const {
        data: { publicUrl },
        error: urlError,
      } = supabase.storage.from("avatars").getPublicUrl(filePath);

      if (urlError) {
        setError("Error getting public URL");
        setLoading(false);
        return;
      }

      setProfileData((prev) => ({ ...prev, avatar: publicUrl }));
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updates = {
      first_name: profileData.firstName,
      last_name: profileData.lastName,
      bio: profileData.bio,
      username: profileData.username,
      role_id: parseInt(profileData.role),
      onboarding: true,
      website: profileData.website,
      avatar_url: profileData.avatar,
    };

    const { error } = await supabase
      .from("user_profiles")
      .update(updates)
      .eq("id", user.id);

    setLoading(false);

    if (error) {
      setError("Failed to update profile. Please try again.");
    } else {
      navigate("/home");
    }
  };

  const nextStep = () => setStep((prev) => Math.min(prev + 1, 5));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 0));

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">
              Verify Your School Email
            </h3>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                School Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={profileData.email || ""}
                onChange={handleInputChange}
                placeholder="Enter your school email"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              />
            </div>
            <button
              onClick={handleVerifyEmail}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Send Verification Email
            </button>
            {verificationSent && (
              <p className="text-green-500 mt-2">
                Verification email sent! Please check your inbox.
              </p>
            )}
          </>
        );
      case 1:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">Verify OTP</h3>
            <div>
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Enter OTP
              </label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter the OTP sent to your email"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              />
            </div>
            <button
              onClick={handleVerifyOtp}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Verify OTP
            </button>
            {otpVerified && (
              <p className="text-green-500 mt-2">OTP verified successfully!</p>
            )}
          </>
        );

      case 2:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">Basic Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={profileData.username}
                onChange={handleInputChange}
                placeholder="Username"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">About You</h3>
            <div>
              <label
                htmlFor="bio"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Bio
              </label>
              <textarea
                id="bio"
                name="bio"
                value={profileData.bio}
                onChange={handleInputChange}
                placeholder="Tell us about yourself"
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              ></textarea>
            </div>
            <div className="mt-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Website
              </label>
              <input
                type="text"
                id="website"
                name="website"
                value={profileData.website}
                onChange={handleInputChange}
                placeholder="Website"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              />
            </div>
          </>
        );
      case 4:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">Your Role</h3>
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Role
              </label>
              <select
                id="role"
                name="role"
                value={profileData.role}
                onChange={handleInputChange}
                required
                className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:ring-2 focus:ring-over-yonder transition-colors duration-200"
              >
                <option value="">Select your role</option>
                <option value="1">Student</option>
                <option value="2">Admin</option>
                <option value="3">Faculty</option>
              </select>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <h3 className="text-xl font-semibold mb-4">Profile Picture</h3>
            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Upload Profile Picture
              </label>
              <input
                type="file"
                id="avatar"
                name="avatar"
                onChange={handleFileChange}
                accept="image/*"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-over-yonder"
              />
            </div>
            {profileData.avatar && (
              <div className="mt-4">
                <img
                  src={profileData.avatar}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover"
                />
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex-shrink-0">
            <h1 className="text-2xl text-over-yonder-default font-black">
              OverYonder
            </h1>
          </div>
          <button
            onClick={handleSignOut}
            className="bg-over-yonder-default text-white px-4 py-2 rounded-md"
          >
            Sign Out
          </button>
        </div>
      </header>
      <div className="min-h-screen bg-gray-100 flex items-center justify-center pb-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Complete Your Profile
          </h2>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            {renderStep()}
            <div className="flex justify-between mt-6">
              {step > 0 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-over-yonder"
                >
                  Previous
                </button>
              )}
              {step < 5 && (
                <button
                  type="button"
                  onClick={nextStep}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-over-yonder-default hover:bg-over-yonder-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-over-yonder"
                >
                  Next
                </button>
              ) } { step === 5 && (
                <button
                  type="submit"
                  className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-over-yonder-default hover:bg-over-yonder-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-over-yonder ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Complete Onboarding"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Onboarding;
