import React from "react";
import { useCallback, useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

const Maps = (props) => {
  const mapContainerStyle = {
    width: "400px",
    height: "300px",
  };

  const center = {
    lat: props.lat, // default latitude
    lng: props.lng, // default longitude
  };

  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);


  return (
    <div className="z-0">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <Map
          style={mapContainerStyle}
          defaultCenter={{ lat: center.lat, lng: center.lng }}
          defaultZoom={17}
          mapId={"5b265503b1ce0308"}
          gestureHandling={"none"}
          disableDefaultUI={true}
        />
        <AdvancedMarker
          ref={markerRef}
          onClick={handleMarkerClick}
          position={{ lat: center.lat, lng: center.lng }}
        >
          <Pin
            background={"#745296"}
            borderColor={"#745296"}
            glyphColor={"#a489be"}
             scale={2}
          />
        </AdvancedMarker>
        {infoWindowShown && (
          <InfoWindow
            anchor={marker}
            headerContent={<h1>{props.event}</h1>}
            onClose={handleClose}
          >
            {props.desc && props.desc.split("\n").map(
              (line, index) => <p key={index}>{line}</p>
            )}

          </InfoWindow>
        )}
      </APIProvider>
    </div>
  );
};

const NewLocationMaps = (props) => {
  const mapContainerStyle = {
    width: "400px",
    height: "300px",
  };

  const [center, ] = useState({
    lat: props.lat, // default latitude
    lng: props.lng, // default longitude
  });

  const [location, setLocation] =useState({
    lat:0,
    lng:0
  })

  
const changeCenter = (newCenter) => {
  if (!newCenter) return;
  setLocation({ lat:newCenter.latLng?.lat(), lng: newCenter.latLng?.lng() });
  props.setLat(newCenter.latLng?.lat());
  props.setLon(newCenter.latLng?.lng());
  console.log(location)
};


  return (
    <div>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <Map
          style={mapContainerStyle}
          defaultCenter={{ lat: center.lat, lng: center.lng }}
          defaultZoom={17}
          mapId={"5b265503b1ce0308"}
          gestureHandling={"greddy"}
          disableDefaultUI={true}
        />
        <AdvancedMarker
          draggable
          onDrag={changeCenter}
          position={center}
        >
          <Pin
            background={"#745296"}
            borderColor={"#745296"}
            glyphColor={"#a489be"}
          />
        </AdvancedMarker>
      </APIProvider>
    </div>
  );
};

export { Maps, NewLocationMaps};
