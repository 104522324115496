import React, { useEffect } from "react";
import { useAuth } from "../utils/AuthContext";
import Events from "./Events";
import { supabase } from "../utils/supaBaseClient";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const { data, error } = await supabase
        .from("user_profiles")
        .select("onboarding")
        .eq("id", user.id)
        .single();

      if (data && !data.onboarding) {
        navigate("/onboarding");
      }
    };

    if (user) {
      checkOnboardingStatus();
    }
  }, [user, navigate]);
  return <Events user={user} />;
}

export default Home;
